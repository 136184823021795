
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        










































































































.performance-overview {
  .el-loading-mask {
    z-index: auto;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $--clb-layout-1;
    max-height: 33px;
    min-height: 33px;

    &-left,
    &-right {
      line-height: $--clb-layout-2;
    }

    &-left {
      h3 {
        margin: 0;
        font-size: $--clb-h3__font-size;
      }
    }

    &-right {
      display: flex;
      padding-top: $--clb-space-1;
      justify-content: flex-start;
      align-items: center;
      min-width: 117px;

      &-title {
        font-size: 12px;
      }

      .skeleton-box {
        margin-left: $--clb-space-2;
      }

      strong {
        font-size: $--clb-font-size-base;
        font-weight: 500;
        padding-left: $--clb-space-2;
        color: $--clb-color__headings;
      }
    }
  }

  &__body {
    .performance-metrics-visualization {
      height: 100%;
      border-radius: $--clb-border-radius;
      padding: 0 0 $--clb-layout-2 0;
    }

    .performance-overview__visualization-skeleton {
      height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-xs});
    }
  }
}

@media (min-width: $--xs) {
  .performance-overview {
    &__header {
      &-right {
        padding-top: $--clb-space-1;
        justify-content: flex-start;
      }

      strong {
        font-size: $--clb-h3__font-size;
        font-weight: 700;
      }
    }

    &__body {
      .performance-overview__visualization-skeleton {
        height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-sm});
      }
    }
  }
}

@media (min-width: $--sm) {
  .performance-overview {
    &__header {
      &-right {
        padding-top: $--clb-space-1;
        justify-content: flex-end;
      }
    }

    &__body {
      .performance-metrics-visualization {
        padding: $--clb-layout-2;
      }

      .performance-overview__visualization-skeleton {
        height: 360px;
      }
    }
  }
}
