
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

























































































































































.dashboard-table {
  display: flex;
  flex-direction: column;

  .dashboard-table-body {
    margin: 0;
  }

  &-row {
    &--empty {
      cursor: unset;
      pointer-events: none;

      .dashboard-table-row__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__header {
    &.dashboard-table-row {
      display: flex;
      cursor: auto;
      color: $--clb-body-font;
      font-size: $--clb-font-size-xs;
      line-height: 16px;
      font-weight: $--clb-font-weight__bold;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 $--clb-space-5 $--clb-space-4;
      margin-bottom: 0;
      border: none;

      .dashboard-table-row__cell {
        &--chart {
          justify-content: center;
        }
      }

      &:hover {
        transform: none;
        color: $--clb-body-font;
        box-shadow: none;
      }
    }
  }
}

@media (max-width: $--xs) {
  .dashboard-table {
    &__header {
      padding: $--clb-space-3;

      .dashboard-table-row__cell {
        color: $--clb-body-font;
        text-shadow: none;

        &--chart {
          display: none;
        }
      }
    }
  }
}

.app-header__portal {
  .dashboard-table {
    width: 100%;
    padding: 0 $--clb-mobile-padding;
  }
}

@media (min-width: $--sm) {
  .app-header__portal {
    .dashboard-table {
      padding: 0 $--clb-layout-4;
      margin: 0 auto;
    }
  }
}

@media (min-width: $--md) {
  .app-header__portal {
    .dashboard-table {
      padding: 0 $--clb-layout-2;
    }
  }
}

@media (min-width: $--lg) {
  .app-header__portal {
    .dashboard-table {
      padding: 0 $--clb-layout-4;
    }
  }
}
