
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

































































































































































.performance-dashboard {
  @include page-layout;

  &__header {
    @apply tw-flex tw-mb-space-4 tw-justify-between tw-items-center;

    &-discover {
      @apply tw-text-sm;
    }
  }

  &__table {
    min-height: 354px;

    @apply tw-mb-layout-2;
  }

  &__latest {
    @apply tw-flex tw-flex-col tw-mb-layout-2;
  }

  &__content-promoted {
    @apply tw-mb-layout-2 xs:tw-mb-layout-1 sm:tw-mb-0 ;

    .campaign-story {
      @apply tw-mr-space-3;
    }

    .campaign-story-skeleton {
      @apply tw-mr-space-3;
    }
  }

  &__chart-filters {
    @apply tw-my-layout-1 tw-flex tw-flex-col sm:tw-flex-row tw-justify-between sm:tw-my-layout-3 sm:tw-mx-0;

    .date-picker-filter {
      @apply tw-w-full sm:tw-max-w-sm sm:tw-m-0;

      &__tabs {
        @apply tw-bg-white;
      }
    }
  }
}
