
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        


































































































































.dashboard-table-row {
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s ease;
  cursor: pointer;
  border: 1px solid #ececec;
  color: #525662;
  margin: 0 0 $--clb-space-3 0;
  padding-left: $--clb-space-1;
  font-weight: 400;
  font-size: 14px;
  border-radius: $--clb-space-1;

  &__wrapper {
    height: 86px;
    padding: $--clb-space-3 $--clb-space-5 $--clb-space-3 $--clb-space-4;
    border-radius: 4px;
    background: #fff;
    display: flex;
  }

  &__cell {
    min-width: 80px;
    width: 80px;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;

    &--name {
      min-width: 200px;
      width: 100%;
      flex-grow: 1;
      display: block;
      z-index: auto;
    }

    &--title {
      font-weight: 700;
      overflow: hidden;
      min-width: 0;
      text-overflow: ellipsis;
    }

    &--chart {
      min-width: 125px;
      justify-content: flex-end;
      overflow: visible;
      z-index: auto;

      .svg-bar-cont {
        border-bottom: 1px solid rgba(243, 245, 253, 0.85);
        height: 63px;
        width: 120px;
        display: flex;
        align-items: flex-end;

        svg {
          margin-bottom: 2px;
        }
      }
    }

    &-skew-background {
      position: absolute;
      height: 86px;
      width: calc(100% + 44px);
      top: -12px;
      right: -36px;
      bottom: 0;
      transform: skew(-10deg);
      border-radius: 2px;
      background: $--clb-color-primary;
    }
  }

  .hidden-xs-to-sm {
    display: none;
  }

  &--prelaunch {
    background-color: $--clb-color-pink;
  }

  &--setup,
  &--capped {
    background-color: $--clb-color-info;
  }

  &--active {
    background-color: #81cf74;
  }

  &--paused {
    background-color: $--clb-color-warning;
  }

  &--cancelled,
  &--expired {
    background-color: $--clb-color-danger;
  }

  &--skeleton {
    background-color: $--clb-color-secondary__light;

    .skeleton-box {
      display: inline;
    }

    .dashboard-table-row__cell--chart {
      .skeleton-box {
        position: absolute;
        right: -$--clb-space-6;
      }
    }
  }

  &:hover:not(&--skeleton) {
    box-shadow: $--box-shadow-light;
    transform: translateY(-3px);
    color: $--jb-light-primary-color;
    border-color: $--jb-light-primary-color;

    .dashboard-table-row__wrapper {
      background: $--color-light;
    }

    .dashboard-table-row__cell--title {
      color: $--jb-dark-primary-color;
    }
  }
}

@media (max-width: $--sm) {
  .dashboard-table-row {
    &__cell {
      min-width: 70px;

      &--name {
        min-width: 120px;
      }

      &--chart {
        min-width: 125px;
      }

      &-skew-background {
        width: calc(100% + 35px);
        transform: skew(-10deg);
        right: -30px;
      }
    }
  }
}

@media (max-width: $--xs) {
  .dashboard-table-row {
    transition: none;

    &__wrapper {
      padding: $--clb-space-3;
    }

    &__cell {
      min-width: 60px;
      color: $--clb-color-primary__white;
      text-shadow: 0 0 3px #666;
      font-weight: 500;

      &--name {
        color: $--clb-body-font;
        min-width: 80px;
        text-shadow: none;
        font-weight: normal;
      }

      &--chart {
        min-width: 90px;
        position: absolute;
        right: 12px;
      }

      &-skew-background {
        min-width: 100px;
        width: calc(100% + 52px) !important;
      }
    }

    .svg-bar-cont {
      border-bottom: 1px solid rgba(243, 245, 253, 0.3);

      rect {
        fill: rgba(243, 245, 253, 0.3);
      }
    }

    &:hover:not(&--skeleton) {
      transform: none;
    }
  }
}
